<template>
    <ModalBox :is-active.sync="isActive">
        <template #header>
            <h5 class="title is-5">
                {{ $t("admin.audit.offers.modal.title") }}
            </h5>
        </template>
        <AuditTableModal
            v-if="offer"
            :offer="offer">
        </AuditTableModal>
    </ModalBox>
</template>

<script>
  import ModalBox from "@/components/Common/Modals/ModalBox.vue";
  import AuditTableModal from "@/components/Admin/Audit/AuditTableModal";
  import { UPDATE_MODAL } from "@core/store/mutation-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";

  export default {
    name: "AuditModal",

    components: {
      ModalBox,
      AuditTableModal
    },

    computed: {
      ...mapFields("admin/audit", {
        fields: [
          "isActive",
          "offer"
        ],
        base: "modal",
        action: UPDATE_MODAL
      })
    }
  };
</script>

<style lang="scss" scoped>
    ::v-deep {
        .animation-content {
            width: 100%;
            max-width: 600px !important;

            .modal-card {
                width: 100%;
                max-width: none;
            }
        }
    }
</style>
