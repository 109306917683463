<template>
    <SectionWrapper>
        <AuditFilters></AuditFilters>
        <AuditTable></AuditTable>
        <AuditModal></AuditModal>
    </SectionWrapper>
</template>

<script>
  import AuditFilters from "@/components/Admin/Audit/AuditFilters";
  import SectionWrapper from "@/components/Common/SectionWrapper";
  import AuditTable from "@/components/Admin/Audit/AuditTable";
  import AuditModal from "@/components/Admin/Audit/AuditModal";
  import { SET_EMPTY } from "@core/store/mutation-constants";
  import { mapActions } from "vuex";

  export default {
    name: "Audit",
    components: {
      SectionWrapper,
      AuditFilters,
      AuditTable,
      AuditModal
    },

    methods: {
      ...mapActions("admin/audit", [SET_EMPTY])
    },

    beforeDestroy () {
      this.SET_EMPTY();
    }
  };
</script>

<style scoped>
</style>
