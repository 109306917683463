<template>
    <div>
        <TablePlaceholder :data="data">
            <template #default="{ items, count }">
                <LTable
                    :data="items"
                    :loading="isLoading"
                    :count="count"
                    query-pagination
                    backend-pagination
                    @update="GET_AUDIT">
                    <b-table-column
                        v-slot="{ row }"
                        :label="$t(`admin.audit.offers.table.labels.date`)"
                        centered>
                        {{ formatEmptyString(date(row.createdAt)) }}
                    </b-table-column>

                    <b-table-column
                        v-slot="{ row }"
                        :label="$t(`admin.audit.offers.table.labels.offer`)">
                        <CustomOfferOutput :offer="offer(row)"></CustomOfferOutput>
                    </b-table-column>

                    <b-table-column
                        v-slot="{ row }"
                        :label="$t(`admin.audit.offers.table.labels.name`)">
                        <AdminOutput
                            v-if="row.initiator && row.initiator.type === 'ADMIN'"
                            :admin="user(row)">
                        </AdminOutput>
                        <WebmasterOutput
                            v-else-if="row.initiator && row.initiator.type === 'WEBMASTER'"
                            :webmaster="user(row)">
                        </WebmasterOutput>
                    </b-table-column>

                    <b-table-column
                        v-slot="{ row }"
                        width="22%"
                        :label="$t(`admin.audit.offers.table.labels.action`)">
                        <div class="columns flex is-align-items-center">
                            {{ $t(`admin.audit.offers.table.values.action.${ row.action }`) }}
                            <span class="has-text-grey-light ml-1">
                                {{ `(${ $t('admin.audit.offers.table.values.action.action') }: ${ changes(row.newData) })` }}
                            </span>
                            <b-button
                                class="bulma-button mx-2"
                                icon-left="eye"
                                icon-pack="far"
                                size="is-small"
                                @click="openWebmastersModal(row)">
                            </b-button>
                        </div>
                    </b-table-column>
                </LTable>
            </template>
        </TablePlaceholder>
    </div>
</template>

<script>
  import TablePlaceholder from "@/components/Common/Table/TablePlaceholder";
  import CustomOfferOutput from "@/components/Common/Output/CustomOfferOutput.vue";
  import LTable from "@/components/Common/LTable";
  import { UPDATE_MODAL } from "@core/store/mutation-constants";
  import { GET_AUDIT } from "@core/store/action-constants";
  import AdminOutput from "@/components/Common/Output/AdminOutput.vue";
  import WebmasterOutput from "@/components/Common/Output/WebmasterOutput.vue";
  import { formatEmptyString } from "@core/filters";
  import { mapActions, mapMutations, mapState } from "vuex";
  import momentjs from "moment";

  export default {
    name: "AuditTable",

    components: {
      CustomOfferOutput,
      TablePlaceholder,
      AdminOutput,
      WebmasterOutput,
      LTable
    },

    computed: {
      ...mapState("admin/audit", [
        "data"
      ]),

      isLoading () {
        return this.$wait(`admin/audit/${ GET_AUDIT }`);
      }
    },

    methods: {
      ...mapActions("admin/audit", [
        GET_AUDIT
      ]),

      ...mapMutations("admin/audit", [
        UPDATE_MODAL
      ]),

      changes (object) {
        return Object.keys(object).length;
      },

      date (date) {
        return momentjs(date).format("DD.MM.YYYY HH:mm");
      },

      name (initiator) {
        return initiator?.login;
      },

      openWebmastersModal (offer) {
        this.UPDATE_MODAL({ isActive: true, offer });
      },

      offer ({ offer }) {
        return { name: offer?.detail?.name, intId: offer?.intId, id: offer?.id, slug: offer?.slug };
      },

      user ({ initiator }) {
        return { login: initiator?.login, intId: initiator?.intId, id: initiator?.id };
      },

      formatEmptyString
    },

    watch: {
      isAddLogoModalActive (value) {
        if (!value) {
          this.emitUpdate();
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
</style>
