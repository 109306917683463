var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TablePlaceholder',{attrs:{"data":_vm.formatTable},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
var count = ref.count;
return [_c('LTable',{attrs:{"data":items,"count":count,"hide-pagination":""}},[_c('b-table-column',{attrs:{"centered":"","label":_vm.$t("admin.audit.offers.modal.table.title.name")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$t(("admin.audit.offers.table.values.name." + (row.name))))+" ")]}}],null,true)}),_c('b-table-column',{attrs:{"centered":"","label":_vm.$t("admin.audit.offers.modal.table.title.oldValue")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"has-text-grey"},[_vm._v(" "+_vm._s(_vm.formatEmptyString(row.oldValue))+" ")])]}}],null,true)}),_c('b-table-column',{attrs:{"centered":"","width":"45"}},[_c('img',{attrs:{"alt":"arrow","src":"/icons/flow_arrow.svg"}})]),_c('b-table-column',{attrs:{"centered":"","label":_vm.$t("admin.audit.offers.modal.table.title.newValue")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatEmptyString(row.newValue))+" ")]}}],null,true)})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }