<template>
    <TablePlaceholder :data="formatTable">
        <template slot-scope="{ items, count }">
            <LTable
                :data="items"
                :count="count"
                hide-pagination>
                <b-table-column
                    v-slot="{ row }"
                    centered
                    :label="$t(`admin.audit.offers.modal.table.title.name`)">
                    {{ $t(`admin.audit.offers.table.values.name.${ row.name }`) }}
                </b-table-column>

                <b-table-column
                    v-slot="{ row }"
                    centered
                    :label="$t(`admin.audit.offers.modal.table.title.oldValue`)">
                    <span class="has-text-grey">
                        {{ formatEmptyString(row.oldValue) }}
                    </span>
                </b-table-column>

                <b-table-column
                    centered
                    width="45">
                    <!--suppress HtmlUnknownTarget -->
                    <img
                        alt="arrow"
                        src="/icons/flow_arrow.svg">
                </b-table-column>
                
                <b-table-column
                    v-slot="{ row }"
                    centered
                    :label="$t(`admin.audit.offers.modal.table.title.newValue`)">
                    {{ formatEmptyString(row.newValue) }}
                </b-table-column>
            </ltable>
        </template>
    </TablePlaceholder>
</template>

<script>
  import TablePlaceholder from "@/components/Common/Table/TablePlaceholder";
  import { formatEmptyString } from "@core/filters";
  import { GET_OFFER_WEBMASTERS } from "@core/store/action-constants";
  import { SET_EMPTY } from "@core/store/mutation-constants";
  import { mapMutations } from "vuex";
  import LTable from "@/components/Common/LTable";
  import momentjs from "moment";

  export default {
    name: "AuditTableModal",

    components: {
      TablePlaceholder,
      LTable
    },

    props: {
      offer: {
        type: Object,
        default: () => {}
      }
    },

    computed: {
      isLoading () {
        return this.$wait(`admin/offer/webmasters/${ GET_OFFER_WEBMASTERS }`);
      },

      formatData () {
        const newData = { ...this.offer.newData };
        const oldData = { ...this.offer.oldData };
        const data = { ...newData, ...oldData };

        for (const key in data) {
          if (key === "withdrawType") {
            newData[key] = this.withdrawType(newData[key]);
            oldData[key] = this.withdrawType(oldData[key]);
          }
          if (key === "deletedAt" || key === "updatedAt") {
            oldData[key] = momentjs(oldData[key]).format("DD.MM.YYYY HH:mm");
            newData[key] = momentjs(newData[key]).format("DD.MM.YYYY HH:mm");
          }
          if (key === "type") {
            oldData[key] = this.type(oldData[key]);
            newData[key] = this.type(newData[key]);
          }
          if (key === "isLaunched") {
            oldData[key] = this.isLaunched(oldData[key]);
            newData[key] = this.isLaunched(newData[key]);
          }
        }

        return {
          newData,
          oldData
        };
      },

      formatTable () {
        const { newData, oldData } = this.formatData;
        const data = {
          ...newData,
          ...oldData
        };
        const items = Object.keys(data).map(key => {
          return {
            name: key,
            oldValue: oldData[key],
            newValue: newData[key]
          };
        });

        return {
          count: items.length,
          items
        };
      }
    },

    methods: {
      ...mapMutations("admin/offer/webmasters", [
        SET_EMPTY
      ]),

      withdrawType (type) {
        return this.$t(`common.entity.withdrawType.${ type }`);
      },

      isLaunched (isLaunched) {
        return this.$t(`admin.audit.offers.table.values.isLaunched.${ isLaunched || "empty" }`);
      },

      type (type) {
        return this.$t(`admin.audit.offers.table.values.offerType.${ type || "empty" }`);
      },

      date (date) {
        return momentjs(date).format("DD.MM.YYYY HH:mm");
      },

      formatEmptyString
    },

    destroyed () {
      this.SET_EMPTY();
    }
  };
</script>

<style lang="scss" scoped>
    ::v-deep {
        .b-table .table th:first-child {
            border-radius: 5px 0 0 0;
        }

        .b-table .table th:last-child {
            border-radius: 0 5px 0 0;
        }

        @media screen and (max-width: 769px) {
            .table tbody tr td:nth-child(3) {
                display: none !important;
            }
        }
    }
</style>
