<template>
    <LFilters
        :filters="auditFilters"
        :loading="isLoading"
        @clear="SET_EMPTY('filters')"
        @confirm="confirmFilters">
        <template #filters>
            <div class="columns is-multiline is-real-mobile-flex">
                <FilterWrapper>
                    <CustomDatepicker
                        v-model="datepicker"
                        class="datetimepicker">
                    </CustomDatepicker>
                </FilterWrapper>
                
                <AccessControlFilter
                    v-model="entityId"
                    type="offer"
                    route-param="entityId"
                    :placeholder="$t(`common.entity.filters.offer`)">
                </AccessControlFilter>

                <FilterWrapper>
                    <b-field :label="$t(`admin.audit.offers.filters.labels.action`)">
                        <Select
                            v-model="action"
                            :searchable="false"
                            class="white"
                            :get-data="getActions"
                            :placeholder="$t(`admin.audit.offers.filters.labels.action`)"
                            field="label"
                            prop="value">
                        </Select>
                    </b-field>
                </FilterWrapper>

                <AccessControlFilter
                    v-model="initiatorUser"
                    type="manager"
                    route-param="initiatorUser"
                    :placeholder="$t(`admin.audit.offers.filters.labels.initiatorUser`)"
                    :label="$t(`admin.audit.offers.filters.labels.initiatorUser`)">
                </AccessControlFilter>
            </div>
        </template>
    </LFilters>
</template>

<script>
  import Select from "@/components/Common/Select/Select.vue";
  import CustomDatepicker from "@/components/Common/Date/CustomDatepicker.vue";
  import AccessControlFilter from "@/components/Admin/Select/AccessControlFilter.vue";
  import LFilters from "@/components/Common/LFilters";
  import {
    GET_INITIAL_WEBMASTERS,
    SYNC_STATISTIC_FILTERS,
    GET_WEBMASTERS_LIST,
    GET_AUDIT
  } from "@core/store/action-constants";
  import {
    SET_EMPTY,
    UPDATE_FILTERS,
    UPDATE_WEBMASTERS_FILTERS,
    UPDATE_WEBMASTERS_LIST_PAGINATION
  } from "@core/store/mutation-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { mapActions, mapMutations, mapState } from "vuex";

  export default {
    name: "AuditFilters",
    components: {
      AccessControlFilter,
      CustomDatepicker,
      LFilters,
      Select
    },

    computed: {
      ...mapFields("filtersConfirm", {
        fields: ["entityId", "action", "initiatorUser", "datepicker"],
        base: "filters",
        action: UPDATE_FILTERS
      }),

      ...mapState("admin/audit",{
        auditFilters: ({ filters }) => filters
      }),

      isLoading () {
        return this.$wait(`admin/audit/${ GET_AUDIT }`);
      },

      filtersSeed () {
        return this.auditFilters;
      }
    },

    methods: {
      ...mapMutations("admin/audit", {
        UPDATE_WEBMASTERS_LIST_PAGINATION: `webmastersList/${ UPDATE_WEBMASTERS_LIST_PAGINATION }`,
        UPDATE_WEBMASTERS_FILTERS: `webmastersList/${ UPDATE_WEBMASTERS_FILTERS }`
      }),

      ...mapActions("admin/audit", {
        GET_WEBMASTERS_LIST: `webmastersList/${ GET_WEBMASTERS_LIST }`,
        GET_INITIAL_WEBMASTERS: `webmastersList/${ GET_INITIAL_WEBMASTERS }`,
        SYNC_STATISTIC_FILTERS,
        UPDATE_FILTERS,
        SET_EMPTY,
        GET_AUDIT
      }),

      getActions () {
        const items = [
          {
            value: "CREATED",
            label: this.$t("admin.audit.offers.filters.values.created")
          },
          {
            value: "UPDATED",
            label: this.$t("admin.audit.offers.filters.values.updated")
          },
          {
            value: "DELETED",
            label: this.$t("admin.audit.offers.filters.values.deleted")
          }
        ];
        return {
          items,
          count: items.length
        };
      },

      confirmFilters ({ filters }) {
        this.UPDATE_FILTERS(filters);
        this.GET_AUDIT();
      }
    }
  };
</script>

<style scoped lang="scss">
    @media screen and (max-width: 770px) {
        .datetimepicker {
            float: none !important;
        }
    }
</style>
