<template>
    <Audit></Audit>
</template>

<script>
  import Audit from "@/components/Admin/Audit/Audit";

  export default {
    name: "AuditView",
    components: { Audit }
  };
</script>

<style scoped>
</style>