var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TablePlaceholder',{attrs:{"data":_vm.data},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
var count = ref.count;
return [_c('LTable',{attrs:{"data":items,"loading":_vm.isLoading,"count":count,"query-pagination":"","backend-pagination":""},on:{"update":_vm.GET_AUDIT}},[_c('b-table-column',{attrs:{"label":_vm.$t("admin.audit.offers.table.labels.date"),"centered":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatEmptyString(_vm.date(row.createdAt)))+" ")]}}],null,true)}),_c('b-table-column',{attrs:{"label":_vm.$t("admin.audit.offers.table.labels.offer")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('CustomOfferOutput',{attrs:{"offer":_vm.offer(row)}})]}}],null,true)}),_c('b-table-column',{attrs:{"label":_vm.$t("admin.audit.offers.table.labels.name")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.initiator && row.initiator.type === 'ADMIN')?_c('AdminOutput',{attrs:{"admin":_vm.user(row)}}):(row.initiator && row.initiator.type === 'WEBMASTER')?_c('WebmasterOutput',{attrs:{"webmaster":_vm.user(row)}}):_vm._e()]}}],null,true)}),_c('b-table-column',{attrs:{"width":"22%","label":_vm.$t("admin.audit.offers.table.labels.action")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"columns flex is-align-items-center"},[_vm._v(" "+_vm._s(_vm.$t(("admin.audit.offers.table.values.action." + (row.action))))+" "),_c('span',{staticClass:"has-text-grey-light ml-1"},[_vm._v(" "+_vm._s(("(" + (_vm.$t('admin.audit.offers.table.values.action.action')) + ": " + (_vm.changes(row.newData)) + ")"))+" ")]),_c('b-button',{staticClass:"bulma-button mx-2",attrs:{"icon-left":"eye","icon-pack":"far","size":"is-small"},on:{"click":function($event){return _vm.openWebmastersModal(row)}}})],1)]}}],null,true)})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }